import React, { useEffect, useMemo, useState } from "react";
import { } from "react-spring";
import "./tabs.scss"

const Tabs = props => {
    const [activeKey, setActiveKey] = useState('');
    const tabs = useMemo(() => {
        return props.tabs || [];
    }, [props.tabs])

    useEffect(() => {
        if (props.tabs?.length > 0 && !activeKey && !props.active) {
            setActiveKey(props.tabs[0]?.key)
        } else {
            setActiveKey(props.active)
        }
    }, [props.tabs, props.active])

    const handleChangeTab = (e, key) => {
        // console.log(e.target)
        setActiveKey(key);
        return props?.onChange(key);
    }

    return <div id="sd-tabs" className="my-5 flex justify-center w-full">
        <div className="tab-container flex justify-center">
            {
                tabs.map(item => <div onClick={e => handleChangeTab(e, item.key)} key={item.key} className={`tab-item ${activeKey === item.key && 'tab-item--active'}`}>
                    {item.content}
                </div>
                )
            }
        </div>
    </div>
}

export default Tabs;