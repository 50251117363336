import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import BlogCard from "../components/BlogLanding/BlogCard"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Pagination from "../components/Pagination/Pagination"
import SpinnerLoader from "../components/Loader/SpinnerLoader"
import Button from "../components/Button/Button"
import Tabs from "../components/Tabs"
import CONSTANT_INFO from "../constant/constant"
import { navigate } from "gatsby"
import axios from "axios"

function ResourceBlogPage({ data, pageContext, location }) {
    const { limit } = pageContext
    const { posts } = data;
    const [loadMore, setLoadMore] = useState(false);
    const [postList, setPostList] = useState([]);
    const [postLimit, setPostLimit] = useState(limit);

    useEffect(() => {
        setPostList(posts?.nodes);
        setPostLimit(limit)
    }, [posts, limit])

    const handleChangePost = (key) => {
        navigate(`/${key}`);
    }

    const fetchMorePost = () => {
        setPostLimit(postLimit + limit);
    }
    const context = {
        pageName: "SD | Resources",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <section className="pt-20">
                <div className="container">
                    <div className="title-container mx-auto flex flex-col py-10 text-center">
                        {/* <h2 className="pre-header">
                            Business and Marketing Resources
                        </h2> */}
                        <h1 className="text-3xl font-bold leading-9 text-black sm:text-4xl md:text-5xl md:leading-[68px]">
                            Business and Marketing Resources
                        </h1>
                    </div>
                    <div>
                        <Tabs onChange={handleChangePost} tabs={CONSTANT_INFO.blogTabs} active="resources" />
                    </div>
                    <div className="py-10">
                        <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
                            {postList?.slice(0, postLimit)?.map(node => (
                                <BlogCard blogCategory="resources" key={node.id} node={node} />
                            ))}
                        </div>
                    </div>
                    <div className="my-20">
                    </div>
                    {(postLimit < postList.length) && <Button
                        onClick={fetchMorePost}
                        type="gray"
                        hasArrow={!loadMore}
                        className={`mx-auto ${loadMore ? "!bg-gray-400" : ""
                            }`}
                    >
                        show older entries
                        {loadMore && <SpinnerLoader />}
                    </Button>}
                    {/* <Pagination currentPage={currentPage} numPages={numPages} /> */}
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        posts: allWpPost(
            sort: { order: DESC, fields: date }
            filter: 
            {
              categories: 
              { 
                nodes: {
                  elemMatch:{
                    name:{
                      eq: "Resources"
                    }
                  }
                }
              }
            }
        ) {
            nodes {
                title
                id
                slug
                categories {
                    nodes {
                        id
                        name
                    }
                }
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        altText
                    }
                }
                excerpt
                date(formatString: "MMMM DD, YYYY")
            }
        }
    }
`

export default ResourceBlogPage
export const Head = () => (
    <SEO
        title="Latest Resources | Saigon Digital"
        description="Browse our Resource library from the Saigon Digital team."
    />
)
