import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React from "react"
import "./blogcard.scss"

function BlogCard({ node, blogCategory = "blog" }) {
    const image = node?.featuredImage
        ? getImage(node?.featuredImage?.node?.localFile)
        : ""
    return (
        <Link
            className="mx-auto mb-8 w-full overflow-hidden border-2 border-solid border-[#F2F2F2] px-3 py-5 duration-300 hover:border-[#3165F5] md:px-5 md:py-[30px]"
            to={`/${blogCategory}/${node?.slug}`}
        >
            <div className="overflow-hidden">
                <div className="aspect-[446/322] w-full">
                    {node?.featuredImage ? (
                        <GatsbyImage
                            image={image}
                            alt={node?.featuredImage?.node?.altText}
                            className="h-full w-full"
                            objectFit="contain"
                        />
                    ) : (
                        <img
                            src="/image-og.jpg"
                            className="h-full w-full"
                            alt={node?.featuredImage?.node?.altText}
                        />
                    )}
                </div>

                <div className="mt-4 px-[14px] pt-[10px] text-left md:mt-7">
                    <p className="mb-5 hidden font-['Poppins']  text-sm font-[240px] leading-[32px] text-[#000000] sm:block sm:leading-8 md:mb-[26px] ">
                        {node?.date}
                    </p>
                    <h3 className="card-title font-['Poppins'] text-xl font-bold leading-9 text-[#1D2333] line-clamp-3 sm:text-[32px] sm:leading-10 ">
                        {node?.title}
                    </h3>
                    <div className="mt-4 line-clamp-3">
                        {parse(node?.excerpt)}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default BlogCard
