import React from "react"
import { Link } from "gatsby"
function Pagination({ currentPage, numPages, pageName = "blog" }) {
    return (
        <nav aria-label="Page navigation" className="mt-10 flex justify-center">
            <ul className="inline-flex -space-x-px">
                <li>
                    <Link
                        to={`${
                            currentPage == 2
                                ? `/${pageName}`
                                : `/${pageName}/${currentPage - 1}`
                        } `}
                        className={`border border-gray-300 py-2 px-3 leading-tight transition duration-300 hover:bg-primary-color hover:text-white ${
                            currentPage == 1 &&
                            "pointer-events-none text-gray-200"
                        }`}
                    >
                        <i class="fa-solid fa-chevron-left"></i>
                    </Link>
                </li>
                {[...Array(numPages)].map((_, index) => {
                    return (
                        <li key={`page_${index}`}>
                            <Link
                                to={`${
                                    index == 0 ? `/${pageName}` : `/${pageName}/${index + 1}`
                                }`}
                                className={`border border-gray-300 py-2 px-3 leading-tight transition duration-300 hover:bg-primary-color hover:text-white active:bg-primary-color active:text-white ${
                                    currentPage === index + 1 ||
                                    (currentPage == 1 &&
                                        currentPage === index + 1)
                                        ? "pointer-events-none border-primary-color bg-primary-color text-white"
                                        : ""
                                }`}
                            >
                                {index + 1}
                            </Link>
                        </li>
                    )
                })}

                <li>
                    <Link
                        to={`/blog/${currentPage + 1}`}
                        className={`border border-gray-300 py-2 px-3 leading-tight transition duration-300 hover:bg-primary-color hover:text-white ${
                            currentPage == numPages &&
                            "pointer-events-none text-gray-200"
                        }`}
                    >
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination
